<template>
<div class="relative">
  <div class="vx-navbar-wrapper navbar-full p-0">
    <VsNavbar class="navbar-custom navbar-skelton" :class="navbarClasses"  :style="navbarStyle" :color="navbarColor">

<!--      <Bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />-->

      <RouterLink tag="div" to="/" class="vx-logo cursor-pointer mx-auto flex items-center">
        <Logo class="w-24 mr-4 fill-current text-primary" />
<!--        <span class="vx-logo-text text-primary" style="font-family: 'Castoro', serif;">Dafamland</span>-->
<!--        <span class="vx-logo-text text-primary">DAFIS</span>-->
      </RouterLink>

      <SearchBar />

      <NotificationDropdown />

      <ProfileDropdown />

    </VsNavbar>
  </div>
</div>
</template>

<script>
// import Bookmarks from './parts/Bookmarks.vue'
import SearchBar from './parts/SearchBar.vue'
import ProfileDropdown from './parts/ProfileDropdown.vue'
import Logo from '../logo/Logo.vue'

export default {
  name: 'NavbarHorizontal',
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Logo,
    // Bookmarks,
    SearchBar,
    ProfileDropdown,
    NotificationDropdown: () => import('./parts/NotificationDropdown.vue')
  },
  computed: {
    navbarColor () {
      let color = '#fff'
      if (this.navbarType === 'sticky') color = '#f7f7f7'
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7'
        }
      }

      if (this.isThemeDark === 'dark') {
        if (color === '#fff') {
          color = '#10163a'
        } else {
          color = '#262c49'
        }
      }

      return color
    },
    isThemeDark () { return this.$store.state.theme.theme },
    navbarStyle () { return this.navbarType === 'static' ? { transition: 'all .25s ease-in-out' } : {} },
    navbarClasses () { return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none' },
    scrollY () { return this.$store.state.theme.scrollY },
    verticalNavMenuWidth () { return this.$store.state.theme.verticalNavMenuWidth },
    windowWidth () { return this.$store.state.theme.windowWidth }
  }
}

</script>
