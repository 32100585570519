<template>
  <div class="relative">
    <div class="vx-navbar-wrapper nav-menu-wrapper">
      <VsNavbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColor">

        <ul class="menu-items flex flex-wrap w-full items-center">
          <li
            class="menu-item"
            v-for="(item, index) in navMenuItems"
            :key="index"
            :class="{'mr-2': !(navMenuItems.length === index+1)}">

            <!-- If header -->
            <template v-if="item.header">
              <HorizontalNavMenuHeader :header="item" class="menu-header relative"/>
            </template>

            <!-- If it's group -->
            <template v-else-if="item.submenu">
              <HorizontalNavMenuGroup
                class="menu-group relative py-4"
                bottom
                :key="`group-${index}`"
                :group="item"
                :groupIndex="index"
                :open="checkGrpChildrenActive(item)"/>
            </template>

            <!-- If it's link -->
            <div v-else-if="item.url" class="menu-link">
              <HorizontalNavMenuItem
                class="relative py-4 cursor-pointer"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :target="item.target"
                :isDisabled="item.isDisabled"
                :slug="item.slug">
                <span class="truncate">{{ item.name }}</span>
                <vs-chip :color="item.tagColor" v-if="item.tag">{{ item.tag }}</vs-chip>
              </HorizontalNavMenuItem>
            </div>

          </li>
        </ul>
      </VsNavbar>
    </div>
  </div>
</template>

<script>
import HorizontalNavMenuGroup from '@/views/components/horizontal-nav-menu/HorizontalNavMenuGroup'
import HorizontalNavMenuHeader from '@/views/components/horizontal-nav-menu/HorizontalNavMenuHeader'
import HorizontalNavMenuItem from '@/views/components/horizontal-nav-menu/HorizontalNavMenuItem'

export default {
  name: 'HorizontalNavMenu',
  props: {
    navMenuItems: {
      type: Array,
      required: true
    }
  },
  components: {
    HorizontalNavMenuGroup,
    HorizontalNavMenuHeader,
    HorizontalNavMenuItem
  },
  computed: {
    navbarColor () {
      return this.$store.state.theme.theme === 'dark' ? '#10163a' : '#fff'
    }
  },
  methods: {
    checkGrpChildrenActive (group) {
      const currentRouteUrl = this.$route.fullPath
      const currentRouteName = this.$route.name
      let active = false
      const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

      if (group.submenu) {
        group.submenu.forEach((item) => {
          if (active) return true

          if (item.url) {
            if ((currentRouteUrl === item.url || currentRouteName === item.url.name) || (item.slug !== undefined && routeParent === item.slug)) {
              active = true
            } else if (item.submenu) {
              this.checkGrpChildrenActive(item)
            }
          } else {
            if (currentRouteUrl === item.url || (item.slug !== undefined && routeParent === item.slug)) {
              active = true
            } else if (item.submenu) {
              this.checkGrpChildrenActive(item)
            }
          }
        })
      }

      return active
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/components/horizontalNavMenu.scss";
</style>
