<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <span>&copy; {{ new Date().getFullYear() }} <a href="https://www.dafamland.com/" target="_blank" rel="nofollow">PT Dafam Maju Bersama</a></span>
        <span class="md:flex hidden items-center">
          <span>Powered by <a href="https://www.vsoftsystem.com" target="_blank">V-Soft</a></span>
          <!--<feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 heads-6" class="ml-2" />-->
        </span>
    </footer>
</template>

<script>

export default {
  name: 'FooterBar',
  props: {
    classes: {
      type: String
    }
  }
}
</script>
