<template>
  <div class="the-navbar__user-meta flex items-center">

    <div v-if="user.staf" class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ truncateWithEllipses(user.staf.nama, 20) }}</p>
      <small>{{ user.username }} - {{ user.role.name }}</small>
    </div>

    <div v-else class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ truncateWithEllipses(user.rekanan.nama, 20) }}</p>
      <small>{{ user.username }} - {{ user.role.name }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <!--<img src="@/assets/images/portrait/small/avatar-s-11.jpg" alt="User image" width="40" height="40" class="rounded-full shadow-md cursor-pointer block"/>-->
        <div class="bg-primary rounded-full items-center flex justify-center shadow-md cursor-pointer block" style="width: 40px; height: 40px">
          <span class="font-medium text-white" style="padding-top: 1px;" >{{ user.staf ? textProfile(user.staf.nama) : textProfile(user.rekanan.nama)  }}</span>
        </div>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 13rem">

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>-->
<!--            <span class="ml-2">Profile</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="MailIcon" svgClasses="w-4 h-4"/>-->
<!--            <span class="ml-2">Inbox</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4"/>-->
<!--            <span class="ml-2">Tasks</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4"/>-->
<!--            <span class="ml-2">Chat</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4"/>-->
<!--            <span class="ml-2">Wish List</span>-->
<!--          </li>-->

<!--          <vs-divider class="m-1"/>-->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="modalChangePasswordActive = true">
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Change Password</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>

    <ChangePassword v-if="!selectable" :isActive.sync="modalChangePasswordActive"/>
  </div>
</template>

<script>
import authMixin from '@/utilities/mixins/auth-mixin'

export default {
  mixins: [authMixin],
  data () {
    return {
      modalChangePasswordActive: false
    }
  },
  components: {
    ChangePassword: () => import('@/views/pages/auth/ChangePassword')
  },
  props: {
    selectable: { default: false, type: Boolean }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    truncateWithEllipses (text, max = 15) {
      const cutText = text.substr(0, max - 1)
      const removeWhitespace = cutText.replace(/^[,\s]+|[,\s]+$/g, '').replace(/\s*,\s*/g, ',')
      return removeWhitespace + (text.length > max ? '..' : '')
    },

    textProfile (string, limit = 2) {
      const text = string.replace(/-/g, '')
      const firstCharOnly = text.match(/\b(\w)/g)
      const joined = firstCharOnly.join('')
      return joined.substring(0, limit)
    }
  }
}
</script>
