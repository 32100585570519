<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <ThemeCustomizer
      v-if = "!disableCustomizer"
      :footerType = "footerType"
      :hideScrollToTop = "hideScrollToTop"
      :navbarType = "navbarType"
      :navbarColor = "navbarColor"
      :routerTransition = "routerTransition"
      @toggleHideScrollToTop = "toggleHideScrollToTop"
      @updateFooter = "updateFooter"
      @updateNavbar = "updateNavbar"
      @updateNavbarColor = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" />

    <VerticalNavMenu
      :navMenuItems="menuItems"
      title="DAFIS"
      parent=".layout--main"/>

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay"/>

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <NavbarHorizontal
          :navbarType="navbarType"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <HorizontalNavMenu
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}]"
          :navMenuItems="menuItems"
        />
      </template>

      <template v-else>
        <NavbarVertical
          :navbarColor="navbarColor"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <Transition :name="routerTransition" v-if="!$route.meta.noPageContentTitle">
              <div v-if="($route.meta.breadcrumb || $route.meta.title) && !$route.meta.hideBreadcrumb" class="router-header flex flex-wrap items-center mb-6">
                <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h3 class="mb-1 ml-1">{{ routeTitle }}</h3>
                </div>
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route"/>
              </div>
            </Transition>

            <div class="content-area__content">
              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top"/>
              </back-to-top>
              <transition :name="routerTransition" mode="out-in">
                <router-view :key="$route.path" @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </transition>
            </div>

          </div>
        </div>
      </div>

      <FooterBar/>

    </div>
  </div>
</template>

<script>
import NavbarVertical from '@/views/components/navbar/NavbarVertical'
import NavbarHorizontal from '@/views/components/navbar/NavbarHorizontal'
import VerticalNavMenu from '@/views/components/vertical-nav-menu/VerticalNavMenu'
import HorizontalNavMenu from '@/views/components/horizontal-nav-menu/HorizontalNavMenu'
import FooterBar from '@/views/components/footer/FooterBar'
import BackToTop from 'vue-backtotop'
import menuItems from '@/constant/menu-items'
import themeConfig from '@/config/theme'
import ThemeCustomizer from '@/views/components/theme-customizer/ThemeCustomizer'
import moduleNavbarBadge from '@/store/modules/general/navbar-badge/navbar-badge.store'
import lsk from '@/constant/lsk'
import _ from 'lodash'

export default {
  name: 'Master',
  components: {
    ThemeCustomizer,
    NavbarVertical,
    NavbarHorizontal,
    VerticalNavMenu,
    HorizontalNavMenu,
    FooterBar,
    BackToTop
  },
  beforeCreate () {
    this.$store.registerModule(['general', 'navbarBadge'], moduleNavbarBadge)
  },
  created () {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.theme.mainLayoutType)
  },
  mounted () {
    // set menu items data
    this.menuItems = _.cloneDeep(this.buildMenuItemsData())

    setTimeout(() => {
      this.getNavbarBadgeData()
    }, 300)
  },
  data () {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.title,
      menuItems: []
    }
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.title
    },
    isThemeDark (val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.theme.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
    },
    badges (badges) {
      if (badges && badges.length > 0) {
        this.updateBadgesForMenuItems(badges)
      }
    }
  },
  computed: {
    badges () {
      return this.$store.state.general.navbarBadge.badges
    },
    bodyOverlay () {
      return this.$store.state.theme.bodyOverlay
    },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') {
          return 'content-area-reduced'
        } else if (this.verticalNavMenuWidth === 'reduced') {
          return 'content-area-lg'
        } else {
          return 'content-area-full'
        }
      } else {
        return 'content-area-full'
      }
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.noScroll
    },
    isThemeDark () {
      return this.$store.state.theme.theme === 'dark'
    },
    layoutTypeClass () {
      return `main-${this.mainLayoutType}`
    },
    mainLayoutType () {
      return this.$store.state.theme.mainLayoutType
    },
    navbarClasses () {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () {
      return this.$store.state.theme.verticalNavMenuWidth
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  },
  methods: {
    updateBadgesForMenuItems (badges) {
      const iterateItem = (item) => {
        if (item.slug) {
          const badge = _.find(badges, badge => badge.menu_slug === item.slug)
          if (badge) {
            item.tag = badge.badge_text
            item.tagColor = badge.badge_color
          }
        }
        // iterate child
        if (_.has(item, 'submenu')) {
          item.submenu.forEach(subItem => iterateItem(subItem))
        }
      }

      this.menuItems.forEach(item => iterateItem(item))
    },
    buildMenuItemsData () {
      const filterItem = (item) => {
        // add tag key default value if not exists
        item.tag = item.tag ? item.tag : null
        item.tagColor = item.tagColor ? item.tagColor : null

        // check is menu authorized
        if (_.has(item, 'can')) {
          return this.$can(item.can)
        }

        // check child menu
        if (_.has(item, 'submenu')) {
          const subMenuFiltered = _.filter(item.submenu, item => filterItem(item))
          if (subMenuFiltered.length > 0) {
            item.submenu = subMenuFiltered
          } else {
            return false
          }
        }

        // passed
        return true
      }

      return _.filter(menuItems, item => filterItem(item))
    },
    getNavbarBadgeData () {
      this.$store.dispatch('general/navbarBadge/getData')
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbar (val) {
      if (val === 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff')
      this.navbarType = val
      localStorage.setItem(lsk.navbarType, val)
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      this.isNavbarDark = val !== '#fff'
      localStorage.setItem(lsk.navbarColor, val)
    },
    updateFooter (val) {
      this.footerType = val
      localStorage.setItem(lsk.footerType, val)
    },
    updateRouterTransition (val) {
      this.routerTransition = val
      localStorage.setItem(lsk.routerTransition, val)
    },
    setNavMenuVisibility (layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('theme/TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('theme/updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('theme/TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
      localStorage.setItem(lsk.hideScrollToTop, val)
    }
  }
}

</script>
