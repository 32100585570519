import NavbarBadgeRepository from '@/repositories/general/navbar-badge-repository'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    badges: []
  },

  mutations: {
    UPDATE_BADGES (state, newData) {
      state.badges = _.unionBy(newData, state.badges, item => item.menu_slug)
    }
  },

  actions: {
    async getData ({ dispatch }) {
      await dispatch('getDataForMenuApproval')
    },

    async getDataForMenuApproval ({ commit, state }) {
      await NavbarBadgeRepository.getBadgeDataForApprovalMenu()
        .then(response => {
          const currentApprovalsBadge = _.cloneDeep(_.filter(state.badges, badge => {
            return badge && badge.menu_slug && badge.menu_slug.indexOf('approvals') === 0
          }))
          const currentApprovalsBadgeToNull = _.map(currentApprovalsBadge, badge => {
            return { ...badge, badge_text: null, badge_color: null }
          })
          const approvalsBadge = _.unionBy(response.data.data, currentApprovalsBadgeToNull, item => item.menu_slug)
          commit('UPDATE_BADGES', approvalsBadge)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
